import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import './App.css'

const images = [
  {
    src: '/images/lp.png',
    title: 'Sky Spaghetti',
    description: 'Personal Project after visiting Bangkok, Thailand (Blender and Substance Painter)',
    titleFontSize: '24px', 
    descriptionFontSize: '16px', 
  },
  {
    src: '/images/snake1.png',
    title: 'Quetzacoatl',
    description: 'Personal Project exploring depictions of Aztec gods. Created vertebrae and skull using sculpting tools (Blender and Substance Painter)',
    titleFontSize: '20px',
    descriptionFontSize: '14px', 
  },
  {
    src: '/images/atm1.png',
    title: 'At The Moment',
    description: 'Personal Project created to be interactive prop in video game- optimized textures and vertices for performance (Blender and Substance Painter)',
    titleFontSize: '22px', 
    descriptionFontSize: '18px', 
  },
  {
    src: '/images/ricos1.png',
    title: 'Ricos Drug',
    description: 'Personal Project and baseline for video game developed solely by myself. Ricos Drug was created to give players a peak into the use of psilocybin mushrooms during the Aztec Empire. This project idea was first developed in my undergraduate and is now being carried into my professional portfolio (Blender, Substance Painter, and Unity)',
    titleFontSize: '22px', 
    descriptionFontSize: '18px', 
  },
  {
    src: '/images/sister1.png',
    title: 'Little Sister is Ignoring You',
    description: 'Personal Project take on Big Brother is Watching You (Blender)',
    titleFontSize: '24px', 
    descriptionFontSize: '16px', 
  },
  {
    src: '/images/hammer.png',
    title: 'Forged Hammer',
    description: 'Created for BlackHarpe LLC, intended for use as an NFT that gave owners special offers in crypto space (Blender)',
    titleFontSize: '24px', 
    descriptionFontSize: '16px', 
  },
];

export default function Work() {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="Work">
      <h1 className="Work">WORK</h1>
      <div className="top-left-button">
        <Link to="/" style={{color : 'white' , textDecoration: 'none' }}>Home</Link>
      </div>
      <div className="grid">
        {images.map((image, index) => (
          <div className="image-container" key={index}>
            <img
              src={image.src}
              alt={`Image ${index + 1}`}
              onClick={() => setSelectedImage(image.src)}
            />
            <div className="image-title" style={{ fontSize: image.titleFontSize }}>
              {image.title}
            </div>
            <div className="image-description" style={{ fontSize: image.descriptionFontSize }}>
              {image.description}
            </div>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal" onClick={() => setSelectedImage(null)}>
          <img src={selectedImage} alt="Selected Image" />
        </div>
      )}
    </div>
  );
}