import React from 'react'
import './App.css'
import './HeroSection.css';
import Office from './Office'
import CameraRig from './CameraRig'
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Loader } from '@react-three/drei'
import { EffectComposer, Bloom,  Vignette, DepthOfField,  HueSaturation } from '@react-three/postprocessing'


function HeroSection() {

  return (
    <div className='hero-container'>
      <Canvas shadows antialias={true} camera={{ position: [-8, 5, 8], fov: 50, near: 0.1, far:300}}>
        <Suspense fallback={null}>  
            <spotLight distance={14} intensity={400} decay={1.6} position={[-9,1.5,-6]} angle={5} color="Green" /> 
            <spotLight distance={60} intensity={60} decay={0.9} position={[25,12,40]} angle={7} color="lightblue"  /> 
            <spotLight distance={150} decay={1.7} intensity={500} position={[1,10,-14.2]} angle={1.6}  castShadow color="white"  /> 
            <ambientLight intensity={0.04}/>
            <Office receiveShadow position={[1,0,-8]} rotation={[0,-45.5,0]} scale={1.4}/>

            {/* <OrbitControls/> */}
            <CameraRig/>
            
            <EffectComposer >
              <DepthOfField 
                focalLength={0.085}
                focusDistance={0.04}
                bokehScale={10}
              />
              <Bloom intensity={0.075} luminanceThreshold={0.12} luminanceSmoothing={5} />
              <Vignette  offset={0.5} darkness={0.5} />
              <HueSaturation hue={0} saturation={0.45}/>
            </EffectComposer>

        </Suspense>

      </Canvas>
     <Loader/>
     {/* <div className='overlay'>
        
     </div> */}
    </div>
  );
}


export default HeroSection;