import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Work from './Work'
import About from './About'

function App() {
  
  return (
    <>
    <Router>
      <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/Work' exact element={<Work/>}/>
          <Route path='/About' exact element={<About/>}/>
        </Routes>
    </Router>
    </>
  );
}

export default App;


