import { useFrame } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { easing } from 'maath';
import * as THREE from 'three';

export default function CameraRig({isVisible}) {
  const [moveCamera, setMoveCamera] = useState(false);
  
  useEffect(()=>{
    if (isVisible) {
        setMoveCamera(true);
    }
    
  },[isVisible] );

  useFrame((state, delta) => {
  if(!moveCamera){
    
    const mouseX = state.pointer.x;
   

      easing.damp3(
        state.camera.position,
        [mouseX, -1, 6], 3, delta
      );
      state.camera.lookAt([mouseX, -2, 6], 3, delta);


//terminal screen view
  }else{

    easing.damp3(
      state.camera.position,
      new THREE.Vector3(5, 0.25, -3.6), 1, delta
    )}
    
    let target = new THREE.Vector3(10, -1.5, -50);
    state.camera.lookAt(target);
  }
  );

  return null;

}