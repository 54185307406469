import React from 'react'
import './App.css'
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div className='about-container'>
      <div className='about-content'>
        <div className='about-image'>
          <img src='/images/about.png' alt='About' />
        </div>
        <div className='about-text'>
          <h1>ABOUT</h1>
          <p>
            3D ARTIST/ WEB DEV/ PRODUCTION ARTIST
          </p>
          <p>
            matthew.proteau@gmail.com // (714) 342-9126
          </p>
          <div className="top-left-button">
              <Link to="/" style={{color : 'white' , textDecoration: 'none' }}>Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

