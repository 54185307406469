import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useCursor, useAnimations, Html } from "@react-three/drei";
import { useFrame } from "react-three-fiber";
import { MeshPhysicalMaterial} from 'three';
import { Clock } from "./Clock.js";
import './App.js'
import CameraRig from "./CameraRig.js";

export default function Office(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/office.gltf')
  const { actions } = useAnimations(animations, group)
  const animationFrameRate = 60; 
  const [hovered, hover] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false); 
  const [isPointerOver, setIsPointerOver] = useState(false);
  
  const [cube019Material, setCube019Material] = useState(materials.out);

  useCursor(hovered);

  const Highlight = new MeshPhysicalMaterial({
    color: 'rgb(60, 255, 38)',
    emissive: 'rgb(60, 255, 38)',
    emissiveIntensity: 6, 
    toneMapped: false,
  })

  const flashingMaterial = new MeshPhysicalMaterial({
    color: 'rgb(100, 255, 100)',
    emissive: 'rgb(100, 255, 100)',
    emissiveIntensity: 20,
    toneMapped: false,
  });

  const handlePointerOver = () => { //toggle model highlight
    setCube019Material(Highlight); 
    setIsPointerOver(true)
  };
  const handlePointerOut = () => { //else model texture
    setCube019Material(materials.out); 
    setIsPointerOver(false)
  };

  const toggleAnimation = () => {
    setIsAnimating(!isAnimating);
    setIsVisible(false);
    console.log("Visible?", isVisible)
  };

  useEffect(() => { 
    actions.InsertAction.repetitions = 0;

    let animationTimer;

    if (isAnimating) {
      actions.InsertAction.play();
      actions.InsertAction.clampWhenFinished = true;

      animationTimer = setInterval(() => {
        if (actions.InsertAction.isRunning()  === false) {
            setIsVisible(true);
            console.log('animtation finished')
          clearInterval(animationTimer);
        }
      }, 100 / animationFrameRate);
    } else {
      actions.InsertAction.stop();
      clearInterval(animationTimer);
      setIsVisible(false)
    }

    return () => {
      clearInterval(animationTimer);
    };
  },[isAnimating,isVisible]);

  useFrame(({ clock }) => {
    if (!isPointerOver) {
      const elapsedTime = clock.getElapsedTime();
      const emissiveIntensity = (Math.sin(elapsedTime * 3) + 1) /5; 
      flashingMaterial.emissiveIntensity = emissiveIntensity;
      setCube019Material(flashingMaterial);
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
          <CameraRig isVisible={isVisible}/>
      <group >
      {isVisible && (
      <Html center transform position={[1.25,0.225,-2.575]} distanceFactor={0.85} rotation={[0, Math.PI/2.4,0]} >
        <iframe
          title="Terminal"
          src="https://mattprotz.github.io/Terminal/"
          style={{
            width: '1225px',
            height: '700px',
            border: "none",
            borderRadius: "50px",
            position:'relative',
            filter: 'brightness(1.2)'
          }}
        />
      </Html>
      )}
    </group>
      <group name="Scene">
        <Clock/>
        <group name="Computer2" position={[-0.252, -1.476, -2.978]} rotation={[0, -0.267, 0]}>
          <mesh
            name="Cube004"
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={materials.Case}
          />
          <mesh
            name="Cube004_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={materials.Case2}
          />
          <mesh
            name="Cube004_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_2.geometry}
            material={materials.Plastic}
          />
          <mesh
            name="Cube004_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_3.geometry}
            material={materials.Tray}
          />
          <mesh
            name="Cube004_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_4.geometry}
            material={materials.Glass}
          />

          <mesh
            name="Cube004_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_5.geometry}
            material={materials.Keys}
          />
          <mesh
            name="Cube004_6"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_6.geometry}
            material={materials.Keyboard}
          />


          <mesh
            name="Cube004_7"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_7.geometry}
            onClick={() => console.log("Computer screen clicked")}
            material={materials.Glass}
          />           
        </group>
        <group name="mouse" position={[-0.038, -2.006, -4.606]}>
          <mesh
            name="BezierCurve"
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve.geometry}
            material={materials.mouse}
          />
          <mesh
            name="BezierCurve_1"
            castShadow
            receiveShadow
            geometry={nodes.BezierCurve_1.geometry}
            material={materials['Material.006']}
          />
        </group>
        <group name="Speaker" position={[0.935, -1.028, -5.79]} rotation={[0, -0.192, 0]}>
          <mesh
            name="Cube008"
            castShadow
            receiveShadow
            geometry={nodes.Cube008.geometry}
            material={materials.Box}
          />
          <mesh
            name="Cube008_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_1.geometry}
            material={materials.Top}
          />
          <mesh
            name="Cube008_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_2.geometry}
            material={materials.Bass}
          />
          <mesh
            name="Cube008_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube008_3.geometry}
            material={materials.Middle}
          />
        </group>
        <group name="Computer3" position={[-0.651, -1.476, 0.992]}>
          <mesh
            name="Cube009"
            castShadow
            receiveShadow
            geometry={nodes.Cube009.geometry}
            material={materials.Case}
          />
          <mesh
            name="Cube009_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube009_1.geometry}
            material={materials.Case2}
          />
          <mesh
            name="Cube009_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube009_2.geometry}
            material={materials.Plastic}
          />
          <mesh
            name="Cube009_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube009_3.geometry}
            material={materials.Tray}
          />
          <mesh
            name="Cube009_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube009_4.geometry}
            material={materials.Glass}
          />
          <mesh
            name="Cube009_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube009_5.geometry}
            material={materials.Second_Comp}
          />
        </group>
        <group
          name="Computer4"
          position={[-0.583, -1.07, 4.183]}
          rotation={[0, -0.32, 0]}
          scale={[0.666, 1, 0.717]}>
          <mesh
            name="Cube010"
            castShadow
            receiveShadow
            geometry={nodes.Cube010.geometry}
            material={materials.Case}
          />
          <mesh
            name="Cube010_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube010_1.geometry}
            material={materials.Case2}
          />
          <mesh
            name="Cube010_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube010_2.geometry}
            material={materials.Glass}
          />
        </group>
        <group name="Keyboard" position={[0.542, -0.43, 3.293]} rotation={[-1.373, -0.064, -0.31]}>
          <mesh
            name="Cube017"
            castShadow
            receiveShadow
            geometry={nodes.Cube017.geometry}
            material={materials.Keys}
          />
          <mesh
            name="Cube017_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube017_1.geometry}
            material={materials.Keyboard}
          />
        </group>
        <group name="Speaker001" position={[1.746, -1.028, -7.126]} rotation={[0, -0.74, 0]}>
          <mesh
            name="Cube021"
            castShadow
            receiveShadow
            geometry={nodes.Cube021.geometry}
            material={materials.Box}
          />
          <mesh
            name="Cube021_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube021_1.geometry}
            material={materials.Top}
          />
          <mesh
            name="Cube021_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube021_2.geometry}
            material={materials.Bass}
          />
          <mesh
            name="Cube021_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube021_3.geometry}
            material={materials.Middle}
          />
        </group>
        <group name="Desk" position={[0, -3.049, 0]}>
          <mesh
            name="Cube001"
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials['Top.001']}
          />
          <mesh
            name="Cube001_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials['Metal.001']}
          />
        </group>
        <group name="Lamp" position={[1.382, -2.004, 6.35]} rotation={[Math.PI / 2, 0, 0.929]}>
          <mesh
            name="Mesh"
            castShadow
            receiveShadow
            geometry={nodes.Mesh.geometry}
            material={materials.Shade}
          />
          <mesh
            name="Mesh_1"
            castShadow
            receiveShadow
            geometry={nodes.Mesh_1.geometry}
            material={materials.metal}
          />
          <mesh
            name="Mesh_2"
            castShadow
            receiveShadow
            geometry={nodes.Mesh_2.geometry}
            material={Highlight}
          />
        </group>
        <group name="Room" position={[-4.197, 0, 0]}>
          <mesh
            name="Cube002"
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.top}
          />
          <mesh
            name="Cube002_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.trim}
          />
          <mesh
            name="Cube002_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_2.geometry}
            material={materials.bottom}
          />
          <mesh
            name="Cube002_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_3.geometry}
            material={materials.Glass}
          />
          <mesh
            name="Cube002_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube002_4.geometry}
            material={materials.Glass}
          />
        </group>
        <group name="Blinds" position={[-3.622, 7.026, -3.261]}>
          <mesh
            name="Cube020"
            castShadow
            receiveShadow
            geometry={nodes.Cube020.geometry}
            material={materials.Material}
          />
          <mesh
            name="Cube020_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube020_1.geometry}
            material={materials['Material.002']}
          />
        </group>
        <mesh
          name="Floor"
          castShadow
          receiveShadow
          geometry={nodes.Floor.geometry}
          material={materials['Material.004']}
          position={[2.285, -9.517, 0]}
        />
        <group name="Insert" 
          position={[2.647, -1.467, 1.062]} 
          rotation={[0, 1.191, -1.219]}
          onClick={toggleAnimation}
          onPointerOver={(event)=>{
            handlePointerOver();
            hover(true);
          }}
        onPointerOut={(event)=>{
          handlePointerOut();
          hover(false);
        }}>         
          <mesh
            name="Cube019"
            castShadow
            receiveShadow
            geometry={nodes.Cube019.geometry}
            material={cube019Material}
          />
          <mesh
            name="Cube019_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube019_1.geometry}
            material={materials.in}
          />
          <mesh
            name="Cube019_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube019_2.geometry}
            material={materials.dec}
          />
        </group>
        <mesh
          name="post-it"
          castShadow
          receiveShadow
          geometry={nodes['post-it'].geometry}
          material={materials.MR1}
          position={[1.449, -1.298, -1.625]}
          rotation={[0, 0, 0.139]}
          scale={0.243}
        />

        <mesh
          name="post-it002"
          castShadow
          receiveShadow
          geometry={nodes['post-it002'].geometry}
          material={materials.POST}
          position={[2.215, -1.758, -0.064]}
          rotation={[0.295, 0.458, -1.704]}
          scale={0.243}
        />
        <mesh
          name="post-it003"
          castShadow
          receiveShadow
          geometry={nodes['post-it003'].geometry}
          material={materials.L1}
          position={[1.026, -0.398, -0.238]}
          rotation={[0, 0.273, 0.139]}
          scale={0.243}
        />
        <mesh
          name="post-it004"
          castShadow
          receiveShadow
          geometry={nodes['post-it004'].geometry}
          material={materials.D1}
          position={[0.657, 1.502, 1.543]}
          rotation={[-0.152, 0.349, 0.139]}
          scale={0.243}
        />
        <mesh
          name="post-it005"
          castShadow
          receiveShadow
          geometry={nodes['post-it005'].geometry}
          material={materials.POST}
          position={[0.682, 0.131, 2.248]}
          rotation={[0.265, -0.11, -1.541]}
          scale={0.243}
        />
        <mesh
          name="post-it006"
          castShadow
          receiveShadow
          geometry={nodes['post-it006'].geometry}
          material={materials.POST}
          position={[2.268, -2.064, 2.833]}
          rotation={[-0.29, -0.075, 1.451]}
          scale={0.243}
        />
        <mesh
          name="Cup"
          castShadow
          receiveShadow
          geometry={nodes.Cup.geometry}
          material={materials['Material.001']}
          position={[2.163, -1.628, 1.587]}
          scale={0.379}
        />
        <group
          name="Pen3"
          position={[4.042, -1.97, 0.388]}
          rotation={[-0.083, 1.181, -1.585]}
          scale={0.151}>
          <mesh
            name="Cylinder006"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006.geometry}
            material={materials.blue}
          />
          <mesh
            name="Cylinder006_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006_1.geometry}
            material={materials.Tip}
          />
          <mesh
            name="Cylinder006_2"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006_2.geometry}
            material={materials['Tip tip']}
          />
          <mesh
            name="Cylinder006_3"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006_3.geometry}
            material={materials.Grip}
          />
          <mesh
            name="Cylinder006_4"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder006_4.geometry}
            material={materials.Silver}
          />
        </group>
        <group
          name="Pen2"
          position={[2.163, -1.628, 1.587]}
          rotation={[-2.484, 0.864, -0.606]}
          scale={0.151}>
          <mesh
            name="Cylinder004"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004.geometry}
            material={materials.blue}
          />
          <mesh
            name="Cylinder004_1"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004_1.geometry}
            material={materials.Tip}
          />
          <mesh
            name="Cylinder004_2"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004_2.geometry}
            material={materials['Tip tip']}
          />
          <mesh
            name="Cylinder004_3"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004_3.geometry}
            material={materials.Grip}
          />
          <mesh
            name="Cylinder004_4"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder004_4.geometry}
            material={materials.Silver}
          />
        </group>
        <mesh
          name="P2"
          castShadow
          receiveShadow
          geometry={nodes.P2.geometry}
          material={materials.paper}
          position={[3.24, -2.006, 5.123]}
          rotation={[Math.PI, -0.982, Math.PI]}
          scale={[3.702, 3.428, 4.782]}
        />
        <mesh
          name="P1"
          castShadow
          receiveShadow
          geometry={nodes.P1.geometry}
          material={materials.paper}
          position={[3.944, -2.005, 2.273]}
          rotation={[0, 0.06, 0]}
          scale={[3.051, 1.42, 3.941]}
        />
        <group name="Bulletin" position={[-3.951, 3.472, -9.679]}>
          <mesh
            name="Cube"
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            material={materials.Cork}
          />
          <mesh
            name="Cube_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube_1.geometry}
            material={materials.Frame}
          />
        </group>
      </group>
    </group>
  )
}
useGLTF.preload('/office.gltf')