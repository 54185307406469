import React, { useRef, useEffect, useState } from 'react'
import { useGLTF, useAnimations, Text } from '@react-three/drei'
import { MeshPhysicalMaterial} from 'three';
import { update } from 'three/examples/jsm/libs/tween.module.js';


export function Clock(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/clock.gltf')
  const { actions } = useAnimations(animations, group)

  const now = new Date().toLocaleTimeString();
  const [time, setTime] = useState(now) ;
  setInterval(updateTime, 1000);
  function updateTime(){
    const newTime = new Date().toLocaleTimeString();
    setTime(newTime);
  }

  const Highlight = new MeshPhysicalMaterial({
    color: 'rgb(31, 199, 22)',
    emissive: 'rgb(31, 199, 22)',
    emissiveIntensity: 8, 
    toneMapped: false,
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Clock" position={[3.042, -1.979, -5.721]} rotation={[0, 0.744, 0]}>
          <mesh
            name="Cube013"
            castShadow
            receiveShadow
            geometry={nodes.Cube013.geometry}
            material={materials.Body}
          />
          <mesh
            name="Cube013_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube013_1.geometry}
            material={materials.Face_Border}
          />
          <Text position={[0,0.24,0.5]} scale={0.155} material={Highlight}>
            {time}
          </Text>
          <mesh
            name="Cube013_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube013_2.geometry}
            material={materials.Face_Border}
          />
          <mesh
            name="Cube013_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube013_3.geometry}
            material={materials.Bottom}
          />
        </group>
        <mesh
          name="Plane"
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials['Material.003']}
          position={[-3.887, 4.039, -7.935]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, 1, 0.707]}
        />
        <mesh
          name="Plane001"
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials['Material.005']}
          position={[-3.859, 3.628, -9.456]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[1, 1, 0.707]}
        />
        <mesh
          name="post-it008"
          castShadow
          receiveShadow
          geometry={nodes['post-it008'].geometry}
          material={materials.POST}
          position={[-4.032, 3.171, -11.01]}
          rotation={[3.067, 0.261, -0.11]}
          scale={0.243}
        />
        <mesh
          name="post-it009"
          castShadow
          receiveShadow
          geometry={nodes['post-it009'].geometry}
          material={materials.POST}
          position={[-4.032, 2.648, -10.469]}
          rotation={[-2.976, 0.261, -0.11]}
          scale={0.243}
        />
        <mesh
          name="Plane002"
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials['Material.009']}
          position={[3.12, -2.006, -4.443]}
          rotation={[0, -0.22, 0]}
          scale={[0.787, 0.548, 0.613]}
        />
        <group name="Cube" position={[3.794, -1.863, -7.432]}>
          <mesh
            name="Cube025"
            castShadow
            receiveShadow
            geometry={nodes.Cube025.geometry}
            material={materials['Material.007']}
          />
          <mesh
            name="Cube025_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube025_1.geometry}
            material={materials['Material.008']}
          />
        </group>
        <group name="Cube001" position={[3.794, -1.692, -7.432]} rotation={[0.036, 0.105, -0.004]}>
          <mesh
            name="Cube026"
            castShadow
            receiveShadow
            geometry={nodes.Cube026.geometry}
            material={materials['Material.007']}
          />
          <mesh
            name="Cube026_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube026_1.geometry}
            material={materials['Material.008']}
          />
        </group>
        <group name="Cube002" position={[3.794, -1.528, -7.432]} rotation={[-3.071, 0, -Math.PI]}>
          <mesh
            name="Cube027"
            castShadow
            receiveShadow
            geometry={nodes.Cube027.geometry}
            material={materials['Material.007']}
          />
          <mesh
            name="Cube027_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube027_1.geometry}
            material={materials['Material.008']}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/clock.gltf')