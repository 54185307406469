import React from 'react' 
import './App.css'
import HeroSection from './HeroSection'
import Work from './Work'


function Home() {
    return(
<>
        <HeroSection/>
       
</>

    );
}

export default Home;